function header() {
  const menu_open = $('#btn-open-menu');
  const nav = $('.header-module-nav');
  const hero_homepage = $('.hero-module-homepage');
  const header = $('.header-module');
  const body = $('body');

  if (window.matchMedia('(max-width: 991px)').matches) {
    menu_open.on('click', function () {
      $(this).toggleClass('active');
      nav.toggleClass('active');
      hero_homepage.toggleClass('menu-opened');
      header.toggleClass('menu-opened');
      body.toggleClass('body-hidden');
    });
  }
}

export { header }