function initTexto() {
  if ($('.texto-module').length == 0) {
    return false;
  }

  $('.texto-module-colapsado').each(function () {
    const textoId = $(this).attr('id');
    const texto = $(this).find('.texto-module-conteudo');
    const textoVal = texto.html();
    if (textoVal.replace(/[^\w ]/g, '').split(/\s+/).length > 80) {
      texto.html(trimWords(textoVal, 73));
    }

    $('#' + textoId + '-colapsado').on('click', function () {
      $('#' + textoId).removeClass('texto-module-colapsado-hidden');
      texto.html(textoVal);
    });
  });
}

function trimWords(texto, palavras) {
  const dividirPalavras = texto.split(/\s+/, palavras);
  dividirPalavras.splice(73, 0, '...');
  const novoTexto = dividirPalavras.join(' ');

  return novoTexto;
}

export { initTexto }