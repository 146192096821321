/**
 * Swiper documentation:
 * https://swiperjs.com/swiper-api
 */
import { Swiper, Navigation, Pagination, Autoplay } from 'swiper';
Swiper.use([Navigation, Pagination, Autoplay]);

function initCarrossel() {
  if ($('.swiper-carrossel').length) {
    $('.swiper-carrossel').each(function (index, element) {
      const instance = $(this).data('instance');

      index = new Swiper(element, {
        speed: 500,
        loop: true,
        slidesPerView: 1,
        observer: true,
        observeParents: true,
        autoplay: false,
        autoHeight: true,
        pagination: {
          el: '#swiper-pagination-' + instance,
          type: 'bullets',
          clickable: true,
        },
        breakpoints: {
          768: {
            navigation: {
              nextEl: '#swiper-button-next-' + instance,
              prevEl: '#swiper-button-prev-' + instance,
            },
          },
        },
      });
    });
  }
}

function initColunas() {
  if ($('.swiper-colunas').length) {
    $('.swiper-colunas').each(function (index, element) {
      const instance = $(this).data('instance');

      index = new Swiper(element, {
        speed: 500,
        loop: true,
        slidesPerView: 1,
        observer: true,
        observeParents: true,
        autoplay: false,
        autoHeight: true,
        pagination: {
          el: '#swiper-pagination-' + instance,
          type: 'bullets',
          clickable: true,
        },
      });
    });
  }
}

export { initCarrossel, initColunas }