function initAccordion() {
  if ($('.accordion-module').length == 0) {
    return false;
  }

  $('.accordion-module-panel').on('shown.bs.collapse', function () {
    const id = $(this).attr('id');
    window.scroll({
      top: $('#' + id).offset().top - 200,
      left: 0,
      behavior: 'smooth',
    });
  });
}

export { initAccordion }