function initVideo() {
  if ($('.video-module').length == 0) {
    return false;
  }

  $('.video-module-wrapper').each(function () {
    const player = $(this).find('.video-module-player');
    const playerId = player.attr('id');
    player.on('click', function () {
      const videoId = player.data('video');
      if ($(this).hasClass('video-module-player-hidden')) {
        $(this).removeClass('video-module-player-hidden');
      }
      autoPlayVideo(playerId, videoId);
    });
  });
}

function autoPlayVideo(playerId, videoId) {
  $('#' + playerId).html('<iframe width="520" height="390" src="https://www.youtube.com/embed/' + videoId + '?autoplay=1&rel=0&wmode=transparent" frameborder="0" allowfullscreen  allow="autoplay"></iframe>');
}

export { initVideo }