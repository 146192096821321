function initComunidades() {
  if ($('.single-comunidade').length == 0) {
    return false;
  }

  $('#comunidades-select').on('change', function () {
    const url = $(this).val();
    window.location.href = url;
  });
}

export { initComunidades }