import { initVideo } from '../modules/video';
import { initComunidades } from '../modules/comunidades';
import { initCarrossel, initColunas } from '../modules/slider';
import { initTexto } from '../modules/texto';
import { initAccordion } from '../modules/accordion';
import { header } from '../modules/header';

export default {
  init() {
    $('#botao-topo').on('click', function () {
      window.scroll({
        top: $('body').offset().top,
        left: 0,
        behavior: 'smooth',
      });
    });
  },
  finalize() {
    initVideo();
    initComunidades();
    initCarrossel();
    initTexto();
    initColunas();
    initAccordion();
    header();
  },
};
